import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { NavLink } from 'react-router-dom';
import LogoLNK from '../../../assets/img/lnk/header-lnk.png';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Label from '../../../components/bootstrap/forms/Label';
import { CardBody } from '../../../components/bootstrap/Card';
import AuthModule from '../../../modules/AuthModule';
import showNotification from '../../../components/extras/showNotification';

const accessToken = localStorage.getItem('accessToken');
const appToken = localStorage.getItem('appToken');
const localName = process.env.REACT_APP_NAME;
const decodeToken = appToken ? jwt_decode(appToken) : null;
const cPasKey = 'password';
const cUKey = 'username';

const JobPortalHeader = (dt) => {
	const { setLoading, isOpenLogin, setIsOpenLogin } = dt;
	const [isOpenRegister, setIsOpenRegister] = useState(false);
	const [isOpenForgot, setIsOpenForgot] = useState(false);
	const [isForgot, setIsForgot] = useState(false);
	const [showPass, setShowPass] = useState(false);

	const [status, setStatus] = useState({
		loading: false,
		token: null,
		username: '',
		password: '',
	});
	const navigate = useNavigate();

	useEffect(() => {
		const fetchLogin = () => {
			if (!status.isLoggedIn && !status.loading && !accessToken) {
				return AuthModule.generateToken();
			}
			const parseAccessToken = accessToken && JSON.parse(accessToken);
			if (parseAccessToken && decodeToken && decodeToken.name == localName) {
				navigate('/job');
			}
			return {};
		};
		fetchLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			username: '',
			password: '',
		},
		validate: (values) => {
			const errors = {};
			if (!values[cUKey] || values[cUKey] == '') {
				errors[cUKey] = 'Username Required';
			}
			if (!values[cPasKey] || values[cPasKey] == '') {
				errors[cPasKey] = 'Password Required';
			}
			return errors;
		},
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			handleLogin(values);
		},
	});

	const formikRegister = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: '',
			email: '',
			password: '',
		},
		validate: (values) => {
			const errors = {};
			if (!values.name || values.name == '') {
				errors.name = 'name Required';
			}
			if (!values.email || values.email == '') {
				errors.email = 'Email Required';
			}
			if (!values[cPasKey] || values[cPasKey] == '') {
				errors[cPasKey] = 'Password Required';
			}
			if (values.email && !validateEmail(values.email)) {
				errors.email = 'Email not valid';
			}
			return errors;
		},
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			handleRegister(values);
		},
	});

	const formikForgot = useFormik({
		enableReinitialize: true,
		initialValues: {
			otp_code: '',
			email: '',
			password: '',
		},
		validate: (values) => {
			const errors = {};
			if (!values.email || values.email == '') {
				errors.email = 'Email Required';
			}
			if (!values[cPasKey] || values[cPasKey] == '') {
				errors[cPasKey] = 'Password Required';
			}
			if (isForgot) {
				if (!values.otp_code || values.otp_code == '') {
					errors.otp_code = 'OTP Code Required';
				}
			}
			return errors;
		},
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			handleForgot(values);
		},
	});

	const handleLogin = (values) => {
		if (values.username && values.password) {
			status.loading = true;
			setStatus(status);
			setLoading(true);
			AuthModule.login(values.username, values.password)
				.then(async (res) => {
					if (res.accessToken) {
						const verifyToken = jwt_decode(res.accessToken);
						localStorage.setItem('accessToken', JSON.stringify(res));
						const role = 'guest user';
						if (verifyToken.details.hris_org_tree.current_person) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: `Login Successfully`,
								timer: 2000,
							});
							// get menu
							console.log(role, '=> ');
							await AuthModule.getMenu(role).then(() => {
								window.location.reload();
							});
						}
					}

					status.loading = false;
					setStatus(status);
				})
				.catch((err) => {
					status.loading = false;
					setStatus(status);
					showNotification('Warning!', err, 'danger');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const handleRegister = (values) => {
		values.username = values.email;
		Swal.fire({
			title: 'Are you sures?',
			text: 'Please check your entries!',
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				AuthModule.register(values)
					.then(() => {
						Swal.fire({
							icon: 'success',
							title: 'Success',
							text: `We have sent a email. Please check your email to continue`,
							timer: 2000,
						});
						setIsOpenRegister(false);
					})
					.catch((err) => {
						Swal.fire('Warning', err, 'error');
					})
					.finally(() => {
						setLoading(false);
					});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire('Cancelled', 'Your data is safe :)', 'error');
			}
		});
	};

	const handleForgot = (values) => {
		values.username = values.email;
		Swal.fire({
			title: 'Are you sures?',
			text: 'Please check your entries!',
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				if (isForgot) {
					AuthModule.verifyOtp(values)
						.then(() => {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: `Data has been update Successfully`,
								timer: 2000,
							});
							setIsForgot(false);
							setIsOpenForgot(false);
						})
						.catch((err) => {
							Swal.fire('Warning', err, 'error');
						})
						.finally(() => {
							setLoading(false);
						});
				} else {
					AuthModule.forgotPassword(values)
						.then(() => {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: `Data has been saved Successfully. Please check email ${values.email} for OTP code`,
								timer: 2000,
							});
							setIsForgot(true);
						})
						.catch((err) => {
							Swal.fire('Warning', err, 'error');
						})
						.finally(() => {
							setLoading(false);
						});
				}
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire('Cancelled', 'Your data is safe :)', 'error');
			}
		});
	};

	return (
		<>
			<div className='container-fluid' style={{ borderBottom: '2px solid #EBECED' }}>
				<nav className='navbar'>
					<div className='container-fluid'>
						<span className='navbar-brand'>
							<img src={LogoLNK} alt='LNK' width={300} />
						</span>
						<div className='d-flex'>
							<span className='nav-link'>
								<NavLink
									to='/'
									className={({ isActive }) => (isActive ? 'active' : null)}
									style={{
										color: '#000000',
										fontSize: '20px',
										textDecoration: 'none',
									}}>
									Home
								</NavLink>
							</span>
							<span className='nav-link'>
								<NavLink
									to='/job'
									className={({ isActive }) => (isActive ? 'active' : null)}
									style={{
										fontSize: '20px',
										textDecoration: 'none',
									}}>
									Jobs
								</NavLink>
							</span>
							<Button
								className='btn-sm'
								color='primary'
								onClick={() => {
									setIsOpenLogin(true);
									formik.resetForm();
								}}>
								Login
							</Button>
							<Button
								onClick={() => {
									setIsOpenRegister(true);
									formikRegister.resetForm();
								}}
								className='btn-sm'
								style={{ marginLeft: '3px' }}
								isOutline
								color='primary'>
								Register
							</Button>
						</div>
					</div>
				</nav>
			</div>

			<Modal
				isCentered
				isStaticBackdrop
				isOpen={isOpenLogin}
				setIsOpen={setIsOpenLogin}
				size='md'
				titleId='modal-login'>
				<ModalHeader setIsOpen={setIsOpenLogin} className='p-4'>
					<ModalTitle>Log in to your Account</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<CardBody style={{ marginTop: '-25px' }}>
						<div className='row'>
							<div className='col-12'>
								<FormGroup
									id='username'
									autoComplete='off'
									isFloating
									label='Email'>
									<Input
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										name='username'
										isValid={formik.isValid}
										invalidFeedback={formik.errors.username}
										value={formik.values.username}
										isTouched={formik.touched.username}
									/>
								</FormGroup>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-11'>
								<FormGroup
									id='password'
									isFloating
									autoComplete='off'
									label='Password'>
									<Input
										type={showPass ? 'text' : 'password'}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										name='password'
										isValid={formik.isValid}
										invalidFeedback={formik.errors.password}
										value={formik.values.password}
										isTouched={formik.touched.password}
									/>
								</FormGroup>
							</div>
							<div className='col-1'>
								<Button
									style={{ marginLeft: '-15px' }}
									icon={showPass ? 'eye_slash' : 'eye'}
									color={showPass ? 'primary' : 'light'}
									type='button'
									size='lg'
									onClick={() => setShowPass(!showPass)}
								/>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-12'>
								<Label>Dont have account? </Label>{' '}
								<Label
									style={{ color: '#4d69fa', cursor: 'pointer' }}
									onClick={() => {
										setIsOpenRegister(true);
										setIsOpenLogin(false);
										formikRegister.resetForm();
									}}>
									{' '}
									Register
								</Label>
							</div>
						</div>
						<div className='row'>
							<div className='col-12'>
								<Button
									type='submit'
									color='primary'
									className='w-100 py-3'
									onClick={formik.handleSubmit}>
									Login
								</Button>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-12 text-center'>
								<Label
									style={{ color: '#4d69fa', cursor: 'pointer' }}
									onClick={() => {
										setIsOpenForgot(true);
										setIsOpenLogin(false);
										setIsForgot(false);
										formikForgot.resetForm();
									}}>
									{' '}
									Forgot Password?
								</Label>
							</div>
						</div>
					</CardBody>
				</ModalBody>
			</Modal>
			<Modal
				isCentered
				isStaticBackdrop
				isOpen={isOpenRegister}
				setIsOpen={setIsOpenRegister}
				size='md'
				titleId='modal-register'>
				<ModalHeader setIsOpen={setIsOpenRegister} className='p-4'>
					<ModalTitle>Register</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<CardBody style={{ marginTop: '-25px' }}>
						<div className='row'>
							<div className='col-12'>
								<FormGroup id='name' autoComplete='off' isFloating label='Fullname'>
									<Input
										onChange={formikRegister.handleChange}
										onBlur={formikRegister.handleBlur}
										name='name'
										isValid={formikRegister.isValid}
										invalidFeedback={formikRegister.errors.name}
										value={formikRegister.values.name}
										isTouched={formikRegister.touched.name}
									/>
								</FormGroup>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-12'>
								<FormGroup id='email' isFloating label='Email'>
									<Input
										onChange={formikRegister.handleChange}
										onBlur={formikRegister.handleBlur}
										name='email'
										isValid={formikRegister.isValid}
										invalidFeedback={formikRegister.errors.email}
										value={formikRegister.values.email}
										isTouched={formikRegister.touched.email}
									/>
								</FormGroup>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-11'>
								<FormGroup
									id='password'
									isFloating
									autoComplete='off'
									label='Password'>
									<Input
										type={showPass ? 'text' : 'password'}
										onChange={formikRegister.handleChange}
										onBlur={formikRegister.handleBlur}
										name='password'
										isValid={formikRegister.isValid}
										invalidFeedback={formikRegister.errors.password}
										value={formikRegister.values.password}
										isTouched={formikRegister.touched.password}
									/>
								</FormGroup>
							</div>
							<div className='col-1'>
								<Button
									style={{ marginLeft: '-15px' }}
									icon={showPass ? 'eye_slash' : 'eye'}
									color={showPass ? 'primary' : 'light'}
									type='button'
									size='lg'
									onClick={() => setShowPass(!showPass)}
								/>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-12'>
								<Label>Do you have account? </Label>{' '}
								<Label
									style={{ color: '#4d69fa', cursor: 'pointer' }}
									onClick={() => {
										setIsOpenLogin(true);
										setIsOpenRegister(false);
										formik.resetForm();
									}}>
									{' '}
									Login
								</Label>
							</div>
						</div>
						<div className='row'>
							<div className='col-12'>
								<Button
									type='submit'
									color='primary'
									className='w-100 py-3'
									onClick={formikRegister.handleSubmit}>
									Register
								</Button>
							</div>
						</div>
					</CardBody>
				</ModalBody>
			</Modal>
			<Modal
				isCentered
				isStaticBackdrop
				isOpen={isOpenForgot}
				setIsOpen={setIsOpenForgot}
				size='md'
				titleId='modal-forgot'>
				<ModalHeader setIsOpen={setIsOpenForgot} className='p-4'>
					<ModalTitle>Forgot Password</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<CardBody style={{ marginTop: '-25px' }}>
						<div className='row mt-3'>
							<div className='col-12'>
								<FormGroup id='email' isFloating label='Email'>
									<Input
										onChange={formikForgot.handleChange}
										onBlur={formikForgot.handleBlur}
										name='email'
										isValid={formikForgot.isValid}
										invalidFeedback={formikForgot.errors.email}
										value={formikForgot.values.email}
										isTouched={formikForgot.touched.email}
										readOnly={isForgot}
									/>
								</FormGroup>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-11'>
								<FormGroup
									id='password'
									isFloating
									autoComplete='off'
									label='New Password'>
									<Input
										type={showPass ? 'text' : 'password'}
										onChange={formikForgot.handleChange}
										onBlur={formikForgot.handleBlur}
										name='password'
										isValid={formikForgot.isValid}
										invalidFeedback={formikForgot.errors.password}
										value={formikForgot.values.password}
										isTouched={formikForgot.touched.password}
										readOnly={isForgot}
									/>
								</FormGroup>
							</div>
							<div className='col-1'>
								<Button
									style={{ marginLeft: '-15px' }}
									icon={showPass ? 'eye_slash' : 'eye'}
									color={showPass ? 'primary' : 'light'}
									type='button'
									size='lg'
									onClick={() => setShowPass(!showPass)}
								/>
							</div>
						</div>
						{isForgot && (
							<div className='row mt-3'>
								<div className='col-12'>
									<FormGroup
										id='otp_code'
										isFloating
										autoComplete='off'
										label='OTP Code'>
										<Input
											type='otp_code'
											onChange={formikForgot.handleChange}
											onBlur={formikForgot.handleBlur}
											name='otp_code'
											isValid={formikForgot.isValid}
											invalidFeedback={formikForgot.errors.otp_code}
											value={formikForgot.values.otp_code}
											isTouched={formikForgot.touched.otp_code}
										/>
									</FormGroup>
								</div>
							</div>
						)}
						<div className='row mt-3'>
							<div className='col-12'>
								<Button
									type='submit'
									color='primary'
									className='w-100 py-3'
									onClick={formikForgot.handleSubmit}>
									Submit
								</Button>
							</div>
						</div>
					</CardBody>
				</ModalBody>
			</Modal>
		</>
	);
};

export default JobPortalHeader;
